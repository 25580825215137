
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.money_move_types.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateMoneyMoveTypes" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="money_move_types.money_move_type_name" type="text" :label="$store.getters.language.data.money_move_types.money_move_type_name" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="['in','out']" v-model="money_move_types.money_move_type" dense  filled outlined item-text="money_move_type"
                            item-value="money_move_type" :return-object="false" :label="$store.getters.language.data.money_move_types.money_move_type">
                        </v-select>
                    </v-flex>
                    
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success" type="submit" :disabled="buttonDisable">{{$store.getters.language.data.money_move_types.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/money_move_types.request.js'
    export default {
        data() {
            return {
                money_move_types: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            buttonDisable() {
                return !this.money_move_types.money_move_type_name || 
                    !this.money_move_types.money_move_type
            }
        },
        mounted(){
            this.id = this.$route.params.id
            if(this.$store.state.store_initialized) {
                this.getOneMoneyMoveTypes()
            }
        },
        methods: {
            getOneMoneyMoveTypes() {
                this.loading = true
                requests.getOneMoneyMoveTypes(this.id).then(r =>{
                    if (r.status == 200) {
                        this.money_move_types = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateMoneyMoveTypes() {
                this.loading = true
                requests.updateMoneyMoveTypes(this.id,this.money_move_types).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        let index = this.$store.getters.money_move_types_list.findIndex(obj => obj.money_move_type_id == this.id)
                        this.$store.state.money_move_types_list[index] = r.data.new_data;
                        this.loading = false
                        this.$router.push('/money_move_types-list');
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    